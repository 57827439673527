<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <RegistryHeader
                    @clearSearch="alertsToMe"
                    :title="$t('alerts:alerts')"
                />
                <!-- {{getAlertsTable.items}} -->
                <Table
                    :items="getAlertsTable.items"
                    :headers="headers"
                    :length="getAlertsTable.pages"
                    isSearchDisabled
                    :page="getAlertsTable.page"
                    :sortForMe="true"
                    :buttonHover="buttonHover"
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    @alertsTo="alertsToMe()"
                    @alertsFrom="alertsFromMe()"
                    @alertsArchive="alertsArchive()"
                    :defaultFields="getAlertsSearchFields.searchFields"
                >
                    <Columns
                        slot="columns"
                        @open-modal="
                            action = 'edit'
                            openModal()
                        "
                        @edit-alert="editAlert"
                        :items="getAlertsTable.items"
                    />
                </Table>

                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="action"
                        slot="buttons"
                        @closeModal="closeModal"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="
                                    action = 'create'
                                    openModal()
                                "
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('alerts:createAlerts') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './../../../components/Global/Details/Tabs/Alerts/Columns'
import AEContent from './../../../components/Global/Details/Tabs/Alerts/Modal/Content'
import Buttons from './../../../components/Global/Details/Tabs/Alerts/Modal/Buttons'
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['alerts', 'global'] },
    components: {
        Columns,
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters([
            'getAlertsTable',
            'getAlertsSearchFields',
            'getProfileDetails',
        ]),
    },

    methods: {
        ...mapActions([
            'fetchAlertsTable',
            'fetchUsers',
            'fetchAlert',
            'fetchAllDepartments',
        ]),
        ...mapMutations([
            'clearAlertsModal',
            'clearAlertsErrors',
            'setAlertsTable',
            'setAlertsSearch',
            'setDefaultFields',
            'clearAlertsTable',
        ]),
        closeModal() {
            this.clearAlertsErrors()
            this.open = false
        },
        async openModal() {
            await void this.fetchAllDepartments({ select: 'users' })
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearAlertsModal()
        },
        async editAlert(id) {
            this.fetchAlert({ id: id })
        },
        setSearchFields(searchFields) {
            this.setAlertsSearch(searchFields)
        },
        setSearch(search) {
            this.setAlertsTable({ search, page: 1 })
            this.fetchAlertsTable({
                'to.users._id': this.getProfileDetails._id,
            })
        },
        setPage(page) {
            this.setAlertsTable({ page })
            this.fetchAlertsTable({ where: this.where })
        },
        setSort(sort) {
            this.setAlertsTable({ sort })
            this.fetchAlertsTable({ where: this.where })
        },
        clearTable() {
            this.clearAlertsTable()
            this.fetchAlertsTable({})
        },
        async alertsToMe() {
            this.where = {
                'to.users._id': this.getProfileDetails._id,
                active: true,
            }
            this.getAlertsTable.page = 1
            let response = await this.fetchAlertsTable({
                where: this.where,
            })
            this.buttonHover = 'toMe'
        },

        async alertsFromMe() {
            this.where = {
                'from._id': this.getProfileDetails._id,
                active: true,
            }
            this.getAlertsTable.page = 1
            let response = await this.fetchAlertsTable({
                where: this.where,
            })

            this.buttonHover = 'fromMe'
        },
        async alertsArchive() {
            this.where = {
                $or: [
                    {
                        'to.users._id': this.getProfileDetails._id,
                        active: false,
                    },
                    { 'from._id': this.getProfileDetails._id, active: false },
                ],
            }
            this.getAlertsTable.page = 1
            let response = await this.fetchAlertsTable({
                where: this.where,
            })
            this.buttonHover = 'archive'
        },
    },
    beforeMount() {
        this.alertsToMe()
    },

    data() {
        return {
            action: 'create',
            open: false,
            items: [],
            buttonHover: 'toMe',
            length: 15,
            where: '',
            headers: [
                {
                    text: '',
                    value: 'new',
                    align: 'right',
                    width: '1%',
                    sortable: false,
                },
                {
                    text: this.$t('global:from'),
                    value: 'from',
                    width: '15%',
                },
                {
                    text: this.$t('global:to'),
                    value: 'to',
                    width: '15%',
                },
                {
                    text: this.$t('global:content'),
                    value: 'content',
                    width: '30%',
                },
                {
                    text: this.$t('global:alertSource'),
                    value: 'alertSource.index',
                    width: '15%',
                },
                {
                    text: this.$t('global:term'),
                    value: 'term',
                    width: '8%',
                },
                {
                    text: this.$t('global:priority'),
                    value: 'priority',
                    width: '8%',
                },
                {
                    text: '',
                    value: 'recurringAlert',
                    align: 'right',
                    width: '4%',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '4%',
                    sortable: false,
                },
            ],
        }
    },
}
</script>
